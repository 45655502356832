<template>
  <b-sidebar id="add-new-production-result-type-sidebar"
             :visible="isAddNewProductionResultTypeSidebarActive"
             bg-variant="white"
             sidebar-class="sidebar-lg"
             shadow
             backdrop
             no-header
             right
             @hidden="resetForm"
             @change="(val) => $emit('update:is-add-new-production-result-type-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Production Result Type" : actionType == 1 ? " Update Production Result Type" :
            "Delete Production Result Type" }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(submitForm)" @reset.prevent="resetForm">

          <validation-provider #default="validationContext"
                               name="Result Name"
                               rules="required">
            <b-form-group label="Result Name"
                          label-for="full-name">
              <b-form-input id="full-name"
                            v-model="currentProductionResultType.resultName" autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :disabled="actionType == 2"
                            placeholder="Result Name" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
                               name="Is OK">
            <b-form-group label=""
                          label-for="isOK">
              <b-form-checkbox class="mt-2"
                               :disabled="actionType == 2"
                               id="isOK"
                               v-model="currentProductionResultType.isOK">
                Is OK
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <validation-provider
                               name="Is Active">
            <b-form-group label=""
                          label-for="isActive">
              <b-form-checkbox class="mt-2"
                               :disabled="actionType == 2"
                               id="isActive"
                               v-model="currentProductionResultType.isActive">
                Is Active
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
                      v-if="currentProductionResultType == null || currentProductionResultType.id == null">
              Add
            </b-button>

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                      @click="updateProductionResultType"
                      v-if="currentProductionResultType != null && currentProductionResultType.id != null && actionType == 1">
              Update
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mr-2"
                      v-if="currentProductionResultType != null && currentProductionResultType.id != null && actionType == 2"
                      variant="outline-danger"
                      @click="deleteProductionResultType">
              Delete
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton, BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewProductionResultTypeSidebarActive',
    event: 'update:is-add-new-production-result-type-sidebar-active',
  },
  props: {
    isAddNewProductionResultTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
  },
  methods: {
    deleteProductionResultType() {
      this.$store.dispatch('apps-production-result-type/deleteProductionResultType', this.currentProductionResultType)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-production-result-type-sidebar-active', false)
          this.notification()
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Delete Production Result Type Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    updateProductionResultType() {
      this.$store.dispatch('apps-production-result-type/updateProductionResultType', this.currentProductionResultType)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-production-result-type-sidebar-active', false)
          // this.$emit('show-success-toast')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Production Result Type Successfully Updated',
              icon: 'BellIcon',
            },
          })
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update Production Result Type Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });

    },
    submitForm() {
        this.$store.dispatch('apps-production-result-type/createProductionResultType', this.currentProductionResultType)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-production-result-type-sidebar-active', false)
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Create Production Result Type Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },

  watch: {
    productionresulttypedata: {
      handler: function (n, o) {
        if (n == null || n == undefined) {
          this.currentProductionResultType = {
            id: null,
            name: null,
            productionLocationTypeID: null,
            companyID: null,
            countryID: null,
            isFinal: null,
            isActive: false,
          }
        } else {
          this.currentProductionResultType = n.selectedProductionResultType;
        }
      },
      deep: true
    }

  },
  data() {
    return {
      required,
      currentProductionResultType: null
    }
  },
  inject: ['productionresulttypedata'],
  setup(props, { emit }) {
    const blankProductionResultTypeData = {
      id: null,
      resultName: null,
      isOK: false,
      isActive: false,
    }
    const productionResultTypeData = ref(JSON.parse(JSON.stringify(blankProductionResultTypeData)))
    const {
      getValidationState,
    } = formValidation(productionResultTypeData)

    return {
      productionResultTypeData,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-production-result-type-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
