import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllProductionResultTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/production/ProductionResultType')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createProductionResultType(ctx, productionResultTypeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/production/ProductionResultType/Create', productionResultTypeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateProductionResultType(ctx, productionResultTypeData) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/production/ProductionResultType/Update', productionResultTypeData, { params: { id: productionResultTypeData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteProductionResultType(ctx, productionResultTypeData) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/production/ProductionResultType/Delete', { params: { id: productionResultTypeData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
